import React, { useState } from "react";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import RequestLoader from "../components/RequestLoader";
import baseUrl from "../utils/baseUrl";

const ResetPassword = () => {
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const { token } = useParams(); // Assuming you're using React Router and have a route like '/reset-password/:token'
  const [user, setUser] = useState({
    password: "",
    confirmPassword: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser((prevUser) => ({
      ...prevUser,
      [name]: value,
    }));
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();
    if (user.password !== user.confirmPassword) {
      toast.error("Passwords do not match");
      return;
    }
    setLoader(true);
    try {
      const response = await axios.put(`${baseUrl}/api/user/reset`, {
        token,
        password: user.password,
      });
      toast.success(response.data.message);
      navigate("/login"); // Redirect to login or another appropriate page
    } catch (error) {
      console.error("error: ", error);
      toast.error(error.response?.data?.message || "Something went wrong");
    } finally {
      setLoader(false);
    }
  };

  return (
    <div className="flex items-center justify-center min-h-[80vh]">
      <div className="w-full max-w-md p-8 space-y-8 bg-white rounded-lg shadow-md">
        <h2 className="text-2xl font-bold text-center">Reset your password</h2>
        <form onSubmit={handleResetPassword} className="mt-8 space-y-6">
          <div className="space-y-4">
            <input
              type="password"
              name="password"
              value={user.password}
              onChange={handleChange}
              placeholder="New Password"
              className="w-full px-3 py-2 border border-gray-300 focus:outline-none focus:border-black"
              required
            />
            <input
              type="password"
              name="confirmPassword"
              value={user.confirmPassword}
              onChange={handleChange}
              placeholder="Confirm Password"
              className="w-full px-3 py-2 border border-gray-300 focus:outline-none focus:border-black"
              required
            />
          </div>
          <button
            type="submit"
            className="w-full px-4 py-3 font-semibold text-white bg-black rounded-lg focus:outline-none"
            disabled={loader}
          >
            {loader ? <RequestLoader /> : "Reset Password"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default ResetPassword;
