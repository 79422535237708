export const aboutCardsData = [
  {
    id: 1,
    image: "/images/about/1clock.png",
    title: "Pre-Series A",
    description:
      "Our investment appetite is for early-stage companies in high-growth industries.",
  },
  {
    id: 2,
    image: "/images/about/sass.png",
    title: "Investment Categories",
    description: "Real Estate, Technology, Healthcare, and Finance",
  },
  {
    id: 3,
    image: "/images/about/size.png",
    title: "Check Size",
    description:
      "We lead or co-lead investment amounts from $250,000 - $2,500,000",
  },
  {
    id: 4,
    image: "/images/about/ownership.png",
    title: "Founders",
    description:
      "We seek to support stellar teams with experienced founding members.",
  },
  {
    id: 5,
    image: "/images/about/arr.png",
    title: "Revenue",
    description:
      "Our investment focus is companies with a minimum ARR of $200K+ or companies in the precipice of cash flow.",
  },
  {
    id: 6,
    image: "/images/about/values.png",
    title: "Partners",
    description:
      "We partner with leaders, unafraid, confident, with long term visions and near term profit.",
  },
];

export const investmentData = [
  {
    image: "/images/stylitics.jpg",
    link: "http://stylitics.com/",
    description: "2022 Partial Exit 10x"
  },

  {
    image: "/images/chainraise.png",
    link: "http://chainraise.io/",
    bg: "bg-[#0a0a0aad]",
  },
  {
    image: "/images/trippy.webp",
    link: "http://trippy.vc/",
  },
  {
    image: "/images/tikkun.webp",
    link: "http://tikkunurban.com/",
  },
  {
    image: "/images/arnies.png",
    link: "http://unclearnies.com/",
  },
  {
    image: "/images/watermanbank.webp",
    link: "http://watermanbank.com/",
  },
  {
    image: "/images/lamm.svg",
    link: "http://lammindustries.com/",
    bg: "bg-[#0a0a0aad]",
  },
  {
    image: "/images/psyrx.jpg",
    link: "https://www.psyrx.co/",
  },
  {
    image: "/images/leaf1.png",
    link: "http://leafwellfarm.com/",
  },
  {
    image: "/images/energy.png",
    link: "https://energyx.com/",
  },
  {
    image: "/images/theoakley.jpg",
    link: "https://www.theoakleyapartments.com/",
    description: "2021 Exit 3.5x"
  },
];

export const facts = [
  {
    id: 0,
    icon: "/images/stats/assets.png",
    title: "Assets Under Management",
    items: [
      {
        text: "$45M",
      },
    ],
  },
  {
    id: 1,
    extraCard: true,
  },
  {
    id: 2,
    icon: "/images/stats/growth.png",
    title: "Portfolio Average Value Growth",
    items: [
      {
        text: "10.7x",
      },
    ],
  },
  {
    id: 3,
    icon: "/images/about/values.png",
    title: "Holding Period",
    items: [
      {
        text: "Venture: 4-6 Years",
      },
      {
        text: "Real Estate: 3-7 Years",
      },
    ],
  },
  {
    id: 4,
    icon: "/images/stats/invest.png",
    title: "Investors",
    items: [
      {
        text: "40+",
      },
    ],
  },
  {
    id: 5,
    icon: "/images/about/1clock.png",
    title: "Upcoming Deals",
    items: [
      {
        text: "Community (Real Estate)",
      },
    ],
  },
];

export const teamData = [
  {
    image: "/images/team/yitz.jpg",
    name: "Yitz ‘Isaac’ Klein",
    position: "Founder",
    description:
      "Yitz began managing real estate at 18, and worked for Ralph Herzka at Meridian Capital Group, and later on for Ari Jungreis of Rosewood Realty, before founding Gideon Asset Management, a private equity family office. At Gideon Yitz was the founder and CIO, where he built an impressive portfolio comprised of real estate, venture tech, health care, nightlife, and film. Third House Capital is the next stage of a portfolio founded in 2017.",
  },
  {
    image: "/images/team/amy.jpg",
    name: "Amy Cherry",
    position: "Advisor",
    description:
      "Leafwellfarm.com investor and partner, Amy founded Shou Sugi Ban House a $50M wellness property located in the Hamptons, NY. Amy's background as an international M&A lawyer combined with her entrepreneurial spirit and past success gives Third House Capital an advantage when considering future opportunities.",
  },
  {
    image: "/images/team/yitzy.jpg",
    name: "Yitzy Rosenblum",
    position: "Senior Advisor",
    description:
      "Yitzy is an early investor in Third House Capital. He is a seasoned investor and his role is advisor and co-investor in the funds activities.",
  },
  {
    image: "/images/josh.jpeg",
    name: "Joshua Nelson",
    position: "Tech & dev Analyst",
    description:
      "As an expert in coding management and marketing within the web3 realm, Josh provides developmental research data and management tools across the portfolio. He is a partner at TrippyVC and Tripp3Labs.",
  },
  // {
  //   image: "/images/Jay.jpeg",
  //   name: "Jack Jay",
  //   position: "Partner",
  //   description:
  //     "Jack is a professional healthcare operator and investor. Jack owns and operates a sophisticated and complex assisted care company providing licensed care for thousands of patients.",
  // },
  {
    image: "/images/team/judah.jpg",
    name: "Judah Kunstler CPA (Picker Auerbach Meisles Kunstler CPA)",
    position: "Tax Accounting & Financial Analysis",
    description:
      "Mr. Kunstler received a BS in Accounting and Information Services from City University of New York Queens College and he is a licensed and admitted as a CPA in New York State. He began his career as a staff accountant for the New Jersey Devils hockey team. He then became a senior auditor at Loeb & Troper, Deloitte & Touché and KPMG where his clients included The College of Staten Island, North Shore Long Island Jewish Health System, United Jewish AppealFederation, Cablevision and Bertelsmann. Upon Leaving public accounting Mr. Kunstler was a Controller at 2 startup companies where he implemented accounting standards, budgets and set up the financial software. He worked directly with the venture capital investors where he assisted in securing debt and equity financing. Mr. Kunstler took on the new position of Assistant Controller at Malca-AmitUSA, an international valuables courier company, where he oversaw a 12-person accounting department.Mr. Kunstler worked for 7 years as CFO of Fairway Capital where he oversaw the operations and finances of over 25 Real Estate funds which included a portfolio of over 1,600 properties with investments in excess of $200 million.",
  },
];
