import React, { useEffect, useState } from 'react'
import toast from "react-hot-toast";
import axios from 'axios';

import baseUrl from "../utils/baseUrl";
import PasswordModal from '../components/PasswordModal';
import DataTable from '../components/DataTable';
import Loader from '../components/Loader';
import { useNavigate } from 'react-router-dom';

const Admin = () => {
  const columns = ['Name', 'Phone Number', 'E-mail', 'State', 'Password'];

  const navigate = useNavigate();
  const Info = localStorage.getItem('Info');
  const user = JSON.parse(Info);

  const [userData, setUserData] = useState([]);
  const [selectedUser, setSelectedUser] = useState();
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const handleLogout = () => {
    localStorage.removeItem("Info");
    window.location.reload();
  };

  function handleClick(user) {
    setModalOpen(true);
    setSelectedUser(user);
  };

  const updatePassword = async (newPassword) => {
    try {
        const payload = {
          userId: selectedUser._id,
          password: newPassword
        }

        const response = await axios.put(`${baseUrl}/api/user/newPassword`, payload, {
          headers: {
            Authorization: `${user?.token}`,
            'Content-Type': 'application/json'
          }
        });

        if (response.data.status) {
          setModalOpen(false);
          setModalOpen(false);
          toast.success(response?.data?.message);
          getData();
          return response.data;
        } else {
          setModalOpen(false);
          toast.error(response?.data?.message);
          return null;
        }
    } catch (error) {
      toast.error(error?.response?.data?.message || "Something went wrong");
      setModalOpen(false);
    }
  };

  useEffect(() => {
    if(user?.role !== "admin") {
      navigate('/');
    }
    
    getData();
  }, []);

  const getData = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${baseUrl}/api/user/allUsers`, {
        headers: {
          Authorization: `${user?.token}`
        }
      });
      if (response.data.status) {
        setUserData(response.data.data.users);
      } else {
        console.error("Error or no data:", response.data.message);
      }
    } catch (err) {
      console.error("Error fetching data:", err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className='p-6 md:p-12'>
        <h1 className='pb-8 text-4xl font-bold text-center'>Admin Dashboard</h1>
        <div className='flex justify-between items-center flex-col md:flex-row pb-6 md:pb-0'>
          <h1 className='hidden md:block pb-4 text-xl md:text-3xl font-semibold'>Hello Admin, <br />Welcome to Third House Capital</h1>
          <button className="bg-[#BB2124] text-white text-sm font-semibold py-4 px-8 max-h-[75px] rounded-lg" onClick={()=> handleLogout()}>Logout</button>
        </div>

        {
          loading ?
          (
            <Loader />
          )
          :
          <DataTable columns={columns} data={userData} handleClick={handleClick} />
        }

        {modalOpen && <PasswordModal handleSubmit={updatePassword} setModalOpen={setModalOpen} selectedUser={selectedUser} />}
    </div>
  )
}

export default Admin