import React from "react";

const ExtraStatCard = () => {
  return (
    <div className="bumping w-full md:w-[500px] min-h-[300px] bg-[#fafafa] rounded-lg shadow-xl p-6 flex flex-col items-center transition-transform duration-300 hover:scale-105">
      <div className="flex items-center justify-center rounded-[90px] object-cover mb-2 bg-[#061539] p-8">
        <img src="/images/stats/grow.png" alt="" className="h-[62px] object-contain" />
      </div>
      <div className="text-2xl font-bold text-gray-800 mb-4 text-center">
        Highest Performing Deals
      </div>
      <div className="text-gray-700 text-center text-xl flex items-center justify-center mb-2 gap-1">
        <div className="w-[60px] flex-shrink-0 flex justify-center">
          <img src="/images/energy.png" alt="EnergyX logo" className="max-h-[30px]" />
        </div>
        <div className="ml-2 text-[16px]">22x</div>
      </div>
      <div className="text-gray-700 text-center text-xl flex items-center justify-center mb-2 gap-1">
        <div className="w-[60px] flex-shrink-0 flex justify-center">
          <img src="/images/stylitics.jpg" alt="Stylitics logo" className="max-h-[30px]" />
        </div>
        <div className="ml-2 text-[16px]">16x</div>
      </div>
      <div className="text-gray-700 text-center text-xl flex items-center justify-center mb-2 gap-1">
        <div className="w-[60px] flex-shrink-0 flex justify-center">
          <img src="/images/arnies.png" alt="Uncle Arnies logo" className="max-h-[30px]" />
        </div>
        <div className="ml-2 text-[16px]">6x</div>
      </div>
      <div className="text-xs text-gray-500 text-center mt-4">
        *Performance is subject to change based on upcoming financing, IPO, or capitalization events.
      </div>
    </div>
  );
};

export default ExtraStatCard;
