import { Route, Routes, useLocation } from "react-router-dom";
import Home from "./pages/Home";
import Navbar from "./components/Navbar";
import About from "./pages/About";
import Portfolio from "./pages/Portfolio";
import Team from "./pages/Team";
import Contact from "./pages/Contact";
import Footer from "./components/Footer";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import PressRelease from "./pages/PressRelease";
import { useEffect, useState } from "react";
import NotFound from "./pages/NotFound";
import Keap from "./pages/Keap";
import Stats from "./pages/Stats";
import ForgotPassword from "./pages/ForgotPassword";
import Admin from "./pages/Admin";
import ResetPassword from "./pages/ResetPassword";

function App() {
  const location = useLocation();
  const currentPath = location.pathname;
  const [divClass, setDivClass] = useState("");

  useEffect(() => {
    if (currentPath === "/team" || currentPath === "/about") {
      setDivClass("bg");
    } else if (currentPath === "/") {
      setDivClass("landing");
    } else {
      setDivClass("background");
    }
  }, [currentPath]);

  console.log("divClass: ", divClass);

  return (
    <div className={divClass}>
      <Navbar home={true} />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/dashboard" element={<Admin />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password/:token" element={<ResetPassword />} />
        <Route path="/stats" element={<Stats />} />
        <Route path="/about" element={<About />} />
        <Route path="/investments" element={<Portfolio />} />
        <Route path="/press-release" element={<PressRelease />} />
        <Route path="/team" element={<Team />} />
        <Route path="/contact" element={<Contact />} />
        {/* <Route path="/invest" element={<Keap />} /> */}

        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer home={true} />
    </div>
  );
}

export default App;
