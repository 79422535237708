import React from "react";

const StatCard = ({ fact }) => {
  const imgSource = {
    0: '/images/stats/assets.png',
    4: '/images/stats/invest.png'
  }
  
  return (
    <div className="bumping w-full md:w-[500px] min-h-[300px] bg-[#fafafa] rounded-lg shadow-xl p-6 flex flex-col items-center transition-transform duration-300 hover:scale-105">
    {
      fact.id === 0 || fact.id === 4 ?
      <div className="flex items-center justify-center rounded-[90px] object-cover mb-2 bg-[#061539] p-8">
        <img src={imgSource[fact.id]} alt="icon" className="h-[70px] text-white black-to-white" />
      </div>
      :
      <img src={fact.icon} alt="icon" className="max-h-[130px] mb-4" />
    }
      <div className="text-2xl font-bold text-gray-800 mb-4 text-center">
        {fact.title}
      </div>
      {fact.items.map((item, idx) => (
        <div
          key={idx}
          className="text-gray-700 text-center text-xl"
        >
          {item.text}
        </div>
      ))}
    </div>
  );
};

export default StatCard;
