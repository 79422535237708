import React from "react";

function NotFound() {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen p-4">
      <h1 className="text-4xl md:text-6xl font-extrabold text-gray-800 mb-4">
        404 - Page Not Found
      </h1>
      <p className="text-lg md:text-xl font-medium text-gray-600 mb-8 text-center">
        The page you are looking for does not exist.
      </p>
      <a
        href="/"
        className="px-4 py-2 text-lg font-semibold text-white bg-blue-500 rounded-md hover:bg-blue-700 transition duration-300"
      >
        Go Back to Home
      </a>
    </div>
  );
}

export default NotFound;
