import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/mousewheel";
import { Navigation, Pagination, Mousewheel, Keyboard } from "swiper/modules";
import StatCard from "../components/StatCard";
import { facts } from "../data";
import ExtraStatCard from "../components/ExtraStatCard";

const Stats = () => {
  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-white p-4">
      <div className="w-full max-w-5xl mx-auto min-h-[75vh] flex flex-col items-center">
        <h1 className="text-[30px] lg:text-[46px] font-bold text-gray-800 my-6 md:my-12 text-center">
          Our Performance
        </h1>
        <Swiper
          slidesPerView={1}
          spaceBetween={10}
          centeredSlides={true}
          navigation={true}
          pagination={{ clickable: true }}
          modules={[Navigation, Pagination, Mousewheel, Keyboard]}
          className="w-full flex items-center justify-center"
        >
          {facts.map((fact, index) => (
            <SwiperSlide
              key={index}
              className="w-full flex items-center justify-center"
            >
              <div className="swiper-slide-content">
                {fact.extraCard ? <ExtraStatCard /> : <StatCard fact={fact} />}
              </div>
            </SwiperSlide>
          ))}
          <div className="mt-4 text-lg text-gray-700 text-center">
            Want Access?{" "}
            <a href="/signup" className="underline font-semmibold">
              See attached
            </a>
          </div>
          <div className="h-[40px]"></div>
        </Swiper>
      </div>
    </div>
  );
};

export default Stats;
