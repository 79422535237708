import React, { useState } from "react";

const PasswordModal = ({ handleSubmit, setModalOpen, selectedUser }) => {
  const handleOutsideClick = (e) => {
    if (e.target === e.currentTarget) {
      setModalOpen(false);
    }
  };

  const [password, setPassword] = useState('');

  return (
    <div
      onClick={handleOutsideClick}
      className="bg-[rgb(0,0,0,0.75)] top-0 right-0 bottom-0 left-0 fixed z-50 w-full h-full flex justify-center items-center overflow-x-hidden overflow-y-auto"
    >
      <div className="w-[95%] md:w-[55%] bg-white rounded-md p-8">
        <div className="flex justify-between items-center">
          <h1 className="text-2xl text-black font-semibold">User Information</h1>
          <button onClick={()=> setModalOpen(false)} className="text-black text-2xl font-bold">
            &times;
          </button>
        </div>

        <div className="my-4">
          <label className="text-base text-[#7f7f7f] cursor-pointer">
            Name
          </label>
          <div className="border-[#7f7f7f] w-full text-md px-2 py-2 border-2 outline-none mb-2 bg-[#F6F6F6]">
            {selectedUser.name}
          </div>
        </div>

        <div className="my-4">
          <label className="text-base text-[#7f7f7f] cursor-pointer">
            Email
          </label>
          <div className="border-[#7f7f7f] w-full text-[12px] px-2 py-2 border-2 outline-none mb-2 bg-[#F6F6F6]">
            {selectedUser.email}
          </div>
        </div>

        <div className="my-4">
          <label className="text-base text-[#7f7f7f] cursor-pointer">
            <span className="text-[#ff1917] text-lg font-bold">*</span> Password
          </label>
          <input
            value={password}
            onChange={(e)=> setPassword(e.target.value)}
            className="border-[#7f7f7f] w-full px-2 py-2 border-2 outline-none mb-2"
          />
        </div>

        <div className="w-full flex justify-center my-4">
          <button className="bg-[#CFCFCF] rounded-md py-2 px-12 font-medium" onClick={()=> handleSubmit(password)}>
            Assign Password
          </button>
        </div>
      </div>
    </div>
  );
};

export default PasswordModal;
