import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaBars, FaTimes } from "react-icons/fa";
import { useLocation } from "react-router-dom";

const Navbar = () => {
  const location = useLocation();
  const currentPath = location.pathname;
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [userName, setUserName] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    const logo = document.getElementById("logo");
    const contactButton = document.getElementById("contactText");
    const loginButton = document.getElementById("login");
    if (logo && contactButton && loginButton) {
      logo.classList.add("fade-in");
      contactButton.classList.add("fade-in");
      loginButton.classList.add("fade-in");
    }
  }, []);

  const checkUserToken = () => {
    const Info = localStorage.getItem("Info");
    const user = Info ? JSON.parse(Info) : null;
    const userToken = user?.token;

    if (!userToken || userToken === "undefined") {
      setUserName("");
      return;
    }
    const userName = user?.userId;
    setUserName(userName);
  };

  const handleLogout = () => {
    localStorage.removeItem("Info");
    window.dispatchEvent(new CustomEvent("logout"));
    setUserName("");
  };

  useEffect(() => {
    checkUserToken();
  }, []);

  useEffect(() => {
    const handleLogin = () => checkUserToken();
    const handleLogout = () => {
      setUserName("");
      window.location.reload();
    };

    window.addEventListener("login", handleLogin);
    window.addEventListener("logout", handleLogout);

    return () => {
      window.removeEventListener("login", handleLogin);
      window.removeEventListener("logout", handleLogout);
    };
  }, []);

  return (
    <nav
      className={`px-10 py-8 flex items-center justify-between relative ${
        !showMobileMenu && currentPath === "/" ? "text-white" : "text-black"
      }`}
    >
      {/* Left options for large screens */}
      <div className="hidden md:flex items-center space-x-6 z-50">
        <Link
          to="/about"
          className={`${
            currentPath === "/about" ? "border-b-2 border-black" : ""
          }`}
        >
          About
        </Link>
        <Link
          to="/investments"
          className={`${
            currentPath === "/investments" ? "border-b-2 border-black" : ""
          }`}
        >
          Portfolio
        </Link>
        <Link
          to="/team"
          className={`${
            currentPath === "/team" ? "border-b-2 border-black" : ""
          }`}
        >
          Team
        </Link>
        <Link
          to="/stats"
          className={`${
            currentPath === "/stats" ? "border-b-2 border-black" : ""
          }`}
        >
          Stats
        </Link>
        <Link
          to="/signup"
          className={`${
            currentPath === "/signup" ? "border-b-2 border-black" : ""
          }`}
        >
          INVEST
        </Link>
        <Link
          to="/press-release"
          className={`${
            currentPath === "/press-release" ? "border-b-2 border-black" : ""
          }`}
        >
          PRESS
        </Link>
      </div>

      <div className="md:hidden" />

      {/* Logo */}
      <div className="absolute left-1/2 transform -translate-x-1/2">
        <Link to="/" className="max-h-[160px]">
          <img
            id="logo"
            src={currentPath ? "/images/newLogo1.png" : "/images/newLogo.jpg"}
            alt="Logo"
            className={`max-h-[160px] max-w-[100px] mt-5 md:mt-0 ${
              currentPath ? "md:mt-4" : "mt-0"
            }`}
          />
        </Link>
      </div>

      {/* Right options for large screens */}
      <div className="hidden md:flex items-center space-x-3 z-50">
        <Link to="/contact">
          <button
            id="contactText"
            className="bg-[#F5F5F4] text-black text-sm font-semibold py-5 px-10 rounded"
          >
            Contact Us
          </button>
        </Link>
        {userName ? (
          <>
            {userName && JSON.parse(localStorage.getItem("Info")).role === "admin" ? (
              <button
                className="bg-[#F5F5F4] text-black text-sm font-semibold py-5 px-8 rounded"
                onClick={() => navigate("/dashboard")}
              >
                Dashboard
              </button>
            )
              :
              (
              <button
                className="bg-[#F5F5F4] text-black text-sm font-semibold py-5 px-8 rounded"
                onClick={handleLogout}
              >
                Logout
              </button>
            )}
          </>
        ) : (
          <Link to="/signup">
            <button
              id="login"
              className="bg-[#F5F5F4] text-black text-sm font-semibold py-5 px-8 rounded"
            >
              Login / Sign Up
            </button>
          </Link>
        )}
      </div>

      {/* Mobile menu toggle button */}
      <div className="flex items-center md:hidden">
        {!showMobileMenu ? (
          <FaBars
            className={`${
              currentPath === "/" ? "text-white" : "text-black"
            } text-2xl cursor-pointer z-50`}
            onClick={() => setShowMobileMenu(true)}
          />
        ) : (
          <FaTimes
            className="text-black text-2xl cursor-pointer z-50"
            onClick={() => setShowMobileMenu(false)}
          />
        )}
      </div>

      {/* Mobile menu */}
      {showMobileMenu && (
        <div className="fixed top-0 left-0 w-full h-full bg-white flex flex-col items-center justify-center z-40 space-y-3">
          <Link
            to="/about"
            className={`text-[24px] ${
              currentPath === "/about" ? "border-b-2 border-black" : ""
            }`}
            onClick={() => {
              setShowMobileMenu(false);
            }}
          >
            About
          </Link>
          <Link
            to="/investments"
            className={`text-[24px] ${
              currentPath === "/investments" ? "border-b-2 border-black" : ""
            }`}
            onClick={() => {
              setShowMobileMenu(false);
            }}
          >
            Portfolio
          </Link>
          <Link
            to="/team"
            className={`text-[24px] ${
              currentPath === "/team" ? "border-b-2 border-black" : ""
            }`}
            onClick={() => {
              setShowMobileMenu(false);
            }}
          >
            Team
          </Link>
          <Link
            to="/stats"
            className={`text-[24px] ${
              currentPath === "/stats" ? "border-b-2 border-black" : ""
            }`}
            onClick={() => {
              setShowMobileMenu(false);
            }}
          >
            Stats
          </Link>
          <Link
            to="/signup"
            className={`text-[24px] ${
              currentPath === "/signup" ? "border-b-2 border-black" : ""
            }`}
            onClick={() => {
              setShowMobileMenu(false);
            }}
          >
            INVEST
          </Link>
          <Link
            to="/press-release"
            className={`text-[24px] ${
              currentPath === "/press-release" ? "border-b-2 border-black" : ""
            }`}
            onClick={() => {
              setShowMobileMenu(false);
            }}
          >
            PRESS
          </Link>
          <Link to="/contact" onClick={() => setShowMobileMenu(false)}>
            <button
              id="contactText"
              className="bg-black text-white text-[20px] md:text-sm font-semibold py-5 px-10 rounded mt-24"
            >
              Contact Us
            </button>
          </Link>
          {userName ? (
            <>
              {userName && JSON.parse(localStorage.getItem("Info")).role === "admin" ? (
                <button
                  className="bg-black text-white text-[20px] font-semibold py-5 px-5 rounded"
                  onClick={() => {
                    setShowMobileMenu(false);
                    navigate("/dashboard");
                  }}
                >
                  Dashboard
                </button>
              ):
              (
              <button
                className="bg-black text-white text-[20px] font-semibold py-5 px-5 rounded"
                onClick={() => {
                  handleLogout();
                  setShowMobileMenu(false);
                }}
              >
                Logout
              </button>
              )}
            </>
          ) : (
            <Link
              to="/signup"
              onClick={() => {
                setShowMobileMenu(false);
              }}
            >
              <button
                id="login"
                className="bg-black text-white text-[20px] font-semibold py-5 px-5 rounded"
              >
                Login / Sign Up
              </button>
            </Link>
          )}
        </div>
      )}
    </nav>
  );
};

export default Navbar;
