import React from "react";

const About = () => {
  return (
    <div className="">
      <h3 className="text-[30px] pb-5 lg:text-[46px] font-bold px-8 md:px-12 lg:px-36 text-center">
        Press
      </h3>
      {/* <a
        href={"/Press Release.pdf"}
        target="_blank"
        className="text-[27px] my-8 flex items-center text-blue-800 justify-center lg:text-[36px] font-semibold px-8 md:px-12 lg:px-36 text-center"
        rel="noopener noreferrer"
      >
        Press Release
      </a> */}

      {/* <h3 className="text-[24px] lg:text-[24px] py-14 px-8 md:px-12 lg:px-36 text-center">
        Third House Capital Managed Entity Approved by SEC as Reg CF Entity,
        Expands Access to High-Growth Portfolio New York, NY – May 17, 2024 –
        Third House Capital, a premier investment firm with a diverse and
        high-growth portfolio, announces its approval by the U.S. Securities and
        Exchange Commission (SEC) as a Regulation Crowdfunding (Reg CF) entity.
        This approval empowers Third House Capital to offer co-investment
        opportunities in its latest offerings, allowing smaller check investors
        to participate in high-growth secured investments previously
        unreachable. Expanding Investment Opportunities The firm’s portfolio
        includes industry-leading companies such as:{" "}
      </h3>
      <ul className="text-[24px] space-y-8 text-center lg:text-[24px] py-8 px-8 md:px-12 lg:px-36">
        <li>
          LAMM: Renowned for world-class audio electronics, established in 1993.{" "}
        </li>
        <li>
          Uncle Arnie’s: The #1 best-selling brand in cannabis-infused
          beverages.
        </li>
        <li>
          ChainRaise: A next-generation investing platform for AI, Blockchain,
          and Real Estate.
        </li>
        <li>
          EnergyX: A pioneer in energy solutions, with GM Motors as an investor,
          specializing in lithium extraction.
        </li>
        <li>Tripp3 Labs: Experts in futuristic web development services.</li>
        <li>
          Waterman Bank: Providers of digital banking solutions with a
          user-friendly mobile app.{" "}
        </li>
        <li>Tikkun Urban: Innovators in psychedelic therapy services.</li>
      </ul>

      <h3 className="text-[24px] lg:text-[24px] py-14 px-8 md:px-12 lg:px-36 text-center">
        <span className="font-bold">Stylitics:</span> Trusted by top retailers
        for omnichannel merchandising solutions. Leafwell Farm Real Estate
        Investment Third House Capital is launching the Leafwell Farm Real
        Estate investment, a multi-state tiny home portfolio. This project
        democratizes access to high-growth, secured real estate investments. The
        entity approved for use is a real estate entity tied to the Leafwell
        Farm Real Estate investment. Learn more at leafwellfarm.com. About Yitz
        Klein, Founder of Third House Capital Yitz Klein began his real estate
        career at 18 and founded Gideon Asset Management, where he served as
        CIO. He has developed a dynamic portfolio at Third House Capital and is
        now opening up the Leafwell Farm Real Estate investment to a wider range
        of investors. Trust in Third House Capital Investors can trust Third
        House Capital due to its stellar portfolio and track record of success.
        The firm’s commitment to excellence is reflected in its strategic
        investments and proven performance across various industries.  
        Interactive Digital Presence Third House Capital’s revamped website will
        feature an interactive display, providing insights into each portfolio
        company and investment opportunity. <br /> For more information, visit
        Thirdhousecapital.com or follow us on Instagram at{" "}
        <span className="font-semibold">@ThirdHouseCapital</span>. Interested
        investors can contact us via text at{" "}
        <span className="font-semibold">347-370-2217</span> or email at{" "}
        <span className="font-semibold">info@thirdhousecapital.com</span>. Media
        Contact: Third House Capital, LLC Email:
        <span className="font-semibold">
          info@thirdhousecapital.com
        </span> Phone: <span className="font-semibold">347-370-2217</span> Ft
        Lauderdale FL United States, USA Web:{" "}
        <span className="font-semibold">www.thirdhousecapital.com</span> Attn:
        Meir Goldson, Investor Relations   This press release includes
        forward-looking statements that involve risks and uncertainties. Actual
        results may differ materially from the results anticipated in these
        forward-looking statements..
      </h3>
 */}

      <div className="text-[24px] lg:text-[24px] flex flex-col space-y-8 text-blue-800 pb-8 px-8 md:px-12 lg:px-36 text-center">
        <a
          target="_blank"
          href="https://www.haaretz.com/haaretz-labels/medica/2023-10-04/ty-article-labels/.premium/psyrxs-innovative-next-generation-plant-based-solution-for-depression/0000018a-f9d0-df89-a7bb-fdf9af300000"
        >
          PSYRX's Plant Based Solution for Depression
        </a>
        <a
          target="_blank"
          href="https://news.bloomberglaw.com/us-law-week/ex-big-law-attorney-opens-luxury-hamptons-retreat"
        >
          Luxury Hamptons Retreat
        </a>
        <a
          target="_blank"
          href="https://www.just-drinks.com/news/thc-drinks-producer-uncle-arnies-gets-backing-from-boston-beer-co-founders/"
        >
          Uncle Arnies Backing
        </a>
        <a
          target="_blank"
          href="https://www.retailtouchpoints.com/topics/market-news/stylitics-raises-80m-for-its-ai-powered-visual-merchandising-platform"
        >
          Stylitics Raises $80M
        </a>
        <a
          target="_blank"
          href="https://www.accesswire.com/764506/world-champion-fighter-ian-mccall-joins-tikkun-urban-to-revolutionize-athlete-care-with-psychedelic-therapy"
        >
          Ian McCall Joins Tikkun Urban
        </a>
        <a
          target="_blank"
          href="https://news.gm.com/newsroom.detail.html/Pages/news/us/en/2023/apr/0411-energyx.html"
        >
          GM EnergyX News
        </a>
        <div className="flex flex-wrap justify-center mt-8"></div>
      </div>
      {/* <PressPDFs /> */}
    </div>
  );
};

export default About;
