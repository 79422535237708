import React, { useState } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import baseUrl from "../utils/baseUrl";
import RequestLoader from "../components/RequestLoader";

const ContactForm = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    subject: "",
    message: "",
  });

  const [loader, setLoader] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);
    try {
      const response = await axios.post(
        `${baseUrl}/api/user/submitForm`,
        formData
      );
      toast.success(response.data.message);
      setLoader(false);
    } catch (error) {
      toast.error(error.response?.data?.message || "Something went wrong");
      setLoader(false);
    }
  };

  return (
    <div className="mx-auto min-h-screen flex flex-col items-center">
      <h3 className="font-semibold text-[24px] lg:text-[28px] my-6 px-5">
        Please fill out the form below to get in touch.
      </h3>
      <form
        onSubmit={handleSubmit}
        className="w-full lg:w-[50%] px-5 lg:px-8 pt-3 lg:pb-8 mb-4"
      >
        <div className="mb-6 flex justify-between">
          <div className="w-1/2 mr-2">
            <label
              htmlFor="firstName"
              className="block text-sm font-normal mb-2"
            >
              First Name
            </label>
            <input
              type="text"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              className="appearance-none border border-[#A9A9A9] w-full py-2 px-3 text-black leading-tight focus:outline-none focus:shadow-outline"
              required
            />
          </div>
          <div className="w-1/2 ml-2">
            <label
              htmlFor="lastName"
              className="block text-sm font-normal mb-2"
            >
              Last Name
            </label>
            <input
              type="text"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              className="appearance-none border border-[#A9A9A9] w-full py-2 px-3 text-black leading-tight focus:outline-none focus:shadow-outline"
              required
            />
          </div>
        </div>
        <div className="mb-6">
          <label htmlFor="email" className="block text-sm font-normal mb-2">
            Email
          </label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            className="appearance-none border border-[#A9A9A9] w-full py-2 px-3 text-black leading-tight focus:outline-none focus:shadow-outline"
            required
          />
        </div>
        <div className="mb-6">
          <label htmlFor="subject" className="block text-sm font-normal mb-2">
            Subject
          </label>
          <input
            type="text"
            name="subject"
            value={formData.subject}
            onChange={handleChange}
            className="appearance-none border border-[#A9A9A9] w-full py-2 px-3 text-black leading-tight focus:outline-none focus:shadow-outline"
            required
          />
        </div>
        <div className="mb-6">
          <label htmlFor="message" className="block text-sm font-normal mb-2">
            Message
          </label>
          <textarea
            name="message"
            value={formData.message}
            onChange={handleChange}
            className="appearance-none border border-[#A9A9A9] w-full py-2 px-3 text-black leading-tight focus:outline-none focus:shadow-outline"
            rows="5"
          />
        </div>
        <div className="flex items-center justify-center">
          <button
            type="submit"
            className="text-white bg-black font-normal  py-4 px-6 rounded focus:outline-none focus:shadow-outline"
          >
            {loader ? <RequestLoader /> : "Submit"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default ContactForm;
